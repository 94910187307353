import React from 'react';
import PrivacyPage from './Pages/PrivacyPage';
import {Route, Switch } from 'react-router-dom'
import HomePage from './Pages/HomePage';
import QuickStartPage from './Pages/QuickStartPage';

function MainRouter() {
    return (
      <main style = {{height:"100%", width:"100%"}}> 
        <Switch>
            <Route path="/" component={HomePage} exact/>
            <Route path="/faq" component={QuickStartPage} exact/>
            <Route path="/privacy" component={PrivacyPage} exact/>
            <Route path="/terms" component={PrivacyPage} exact/>
            <Route path="/download" component={() => { 
                window.location.href = 'https://apps.apple.com/app/id1454198192'; 
                return null;
            }} exact/>
        </Switch>
      </main>
    );
}

export default MainRouter;
