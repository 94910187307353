import React, { Component } from 'react';
import './PrivacyPage.css';

class PrivacyPage extends Component {
  constructor(props) {
    super(props);

    this.content = this.content.bind(this);
  }

  render() {
    return (
      <div className="PrivacyPage">
        {this.content()}
      </div>
    );
  }

  content() {
    return (<div className="PrivacyPageContent">
      <h1>Privacy Policy</h1>

      <p>This Privacy Policy explains what information we collect about you and why, what we may do with that information and how we handle it. It applies to the Habit Hopper web service with associated iOS, Android and macOS apps (jointly and severally, the Service). By using the Service you agree to this Privacy Policy.</p>

      <h2>In short</h2>

      <ul>
        <li><strong>Your data is yours</strong></li>
        <li>Habit Hopper values your data and your privacy</li>
        <li>Your data is synced and stored securely</li>
        <li>We will never share your personal data with any third party without your prior authorisation</li>
      </ul>

      <h2>What information do you collect about me?</h2>

      <p>If you choose to create an account in our app, we store:</p>

      <ul>
        <li>Your username in the form of an email address, and password</li>
        <li>Title, subtitle and other related data for tasks created in Habit Hopper</li>
      </ul>

      <p>If you choose to purchase a subscription to Habit Hopper Plus, in addition to the information associated with your account, we store:</p>

      <ul>
        <li>A transaction id for your purchase (if purchased from our website)</li>
        <li>A purchase token (if purchased from the Google Play Store)</li>
        <li>A purchase receipt (if purchased from the iTunes Store)</li>
      </ul>

      <p>If you choose to subscribe to our newsletter, we store your authorisation and send your email address to MailChimp for processing. Learn more about Mailchimp’s privacy practices <a href="https://mailchimp.com/legal/privacy/">here</a>.</p>

      <p>The Service also uses cookies and like technologies to keep you logged in.</p>

      <h2>Why do you collect information about me?</h2>

      <ul>
        <li>To provide the Service to you</li>
        <li>To provide effective support to you during your use of the Habit Hopper service</li>
        <li>To secure your account against unauthorised access</li>
      </ul>

      <h2>Will you send me emails?</h2>

      <p>If you are a registered account holder, we may from time to time contact you with information that we deem important to you as a registered user. We will never spam you or advertise to you.</p>

      <h2>Will you share my information?</h2>

      <p><strong>No.</strong> We will only disclose your information if we have your consent to do so, we are compelled to do so by law, or we do so in connection with the sale or reorganisation of the Service.</p>

      <h2>How is my data stored?</h2>

      <p>Habit Hopper uses third-party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service. The data collected pursuant to this Privacy Policy will be securely stored. We use a variety of technologies to encrypt and secure your information to prevent access by unauthorised parties.</p>

      <h2>Can I delete my information?</h2>

      <p><strong>Yes.</strong> You can delete your sync account by contacting us via our <a href="http://armchairengineering.com/support/">support page</a>.</p>

      <p>After complying with your request all your data will be deleted and you will no longer be able to sign into your account. Any support requests will be retained for our records.</p>

      <h2>Can I have a copy of my information?</h2>

      <p><strong>Yes.</strong> You can request a copy of the data we have stored about you by contacting us via our <a href="http://armchairengineering.com/support/">support page</a>.</p>

      <h2>Habit Hopper Plus</h2>

      <p>The Habit Hopper Plus subscription is US$1.99 per month, and will be charged to your account after your confirmation of purchase. Your subscription will automatically renew every month and be charged to your account. Subscriptions renew automatically unless cancelled at least 24 hours prior to the end of the current period.</p>

      <h2>How to contact us</h2>

      <p>You can obtain further information in relation to this privacy policy or any other part of the Service by contacting us via our <a href="http://armchairengineering.com/support/">support page</a>.</p>

      <h2>Will this Privacy Policy ever change?</h2>

      <p>We may need to update this Policy to reflect changes in the Service, our business and applicable laws. We will, however, always maintain our commitment to respect your privacy. We will post any revisions to this Policy on our website. Your continuation of use of the Service after any change means that you agree with, and consent to be bound by, the new Privacy Policy.</p>

      <h1>Terms of Use</h1>

      <p>The content, applications and services available in connection with Habit Hopper are made available to you subject to these Terms of Use and those terms and conditions set forth in the Privacy Policy above. By using the Application, you agree to follow and be bound by the Terms, which may be updated by us from time to time without notice to you. We and third-party providers may make improvements and/or changes in any part of the Application at any time without notice.</p> 

      <p>The Application is offered and available only to individuals who are 18 years or older, or 13 years or older with parental consent, or the applicable age of majority in your country.  By using or accessing the Application, you represent and warrant that you meet these requirements. The Terms are important and you should read them carefully and contact us with any questions before you use any of the Habit Hopper Applications and associated services.</p>

      <p>By continuing to use the Application you acknowledge and agree that you have had sufficient chance to read and understand the Terms and you agree to be bound by them. If you do not agree to the terms, you should not use the Application including its associated features and services.</p>

      <p>Subject to your compliance with these Terms, Habit Hopper grants you a limited, non-exclusive, non-sublicensable, revocable, non-transferrable license to access and use the Application and any of its content, information and related materials on your personal electronic device, solely for your personal, non-commercial use.</p>

      <p>You acknowledge and agree that we retain complete editorial control over the Application and may alter, amend or cease the operation of the Application at any time in our sole discretion without notice to you; and the Application may be unavailable from time to time (including for maintenance purposes).</p>

      <p>You may not remove any copyright, trademark or other proprietary rights notices from the Application, or edit, decompile, reverse-engineer or disassemble any features of the Application.</p>

      <p>The Application and the Content are the property of Habit Hopper and its licensors, and are protected by copyright, trademark, and other laws of both the United States and foreign countries. Nothing in these Terms constitutes a transfer of any intellectual property rights. Our trade dress may not be used in connection with any product or service without our prior written consent.</p>

      <p>If you provide us any feedback, ideas and suggestions about the Application or Content, you assign to us, your entire right, title and interest. To the extent such rights cannot be assigned, you hereby waive such rights as to Habit Hopper, its affiliates and its and their customers and business partners.</p>

      <p>The Content and Application are provided to you “as is” and without warranty.</p>

      <p></p>

      <p>Thank you for choosing Habit Hopper.</p>

    </div>);
  }
}

export default PrivacyPage;