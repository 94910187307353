import React from 'react';
import logo from '../Resources/HabitHopperLogo.svg';
import appStoreLogo from "../Resources/appStoreLogoWeb.svg";
import './HomePage.css';

let appStoreLink = "https://apps.apple.com/app/id1454198192";

function HomePage() {
  return (
    <div className="HomePage">
      <img src={logo} className="HomePage-logo" alt="logo" />
      <p><b>
        Habit Hopper
      </b></p>
      <p>
        Simple & elegant habit tracking
      </p>
      <div><a href={appStoreLink}><img src={appStoreLogo} alt="Available on the App Store"/></a></div>
    </div>
  );
}

export default HomePage;
