import React, { Component } from 'react';
import './App.css';
import NavBar from './Components/NavBar';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './Routes';

class App extends Component {

  constructor(props) {
    super(props);

    this.openUrl = this.openUrl.bind(this);
  }

  openUrl(url) {
    window.open(url, "_self")
  }

  render() {
    let tabData = [
        {title: "FAQ", action: () => this.openUrl('/faq')}
    ];

    return (
      <div className="App">
        <NavBar tabData={tabData} title={"Habit Hopper"} mainAction={() => this.openUrl('/')}/>
        <div className="AppViewContainer">
          <BrowserRouter>
            <MainRouter />
          </BrowserRouter>
        </div>
      </div>
    );
  }
}

export default App;
