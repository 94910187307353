import React, { Component } from 'react';
import './QuickStartPage.css';

class QuickStartPage extends Component {
  constructor(props) {
    super(props);

    this.content = this.content.bind(this);
  }

  render() {
    return (
      <div className="QuickStartPage">
        {this.content()}
      </div>
    );
  }

  content() {
    return (<div className="QuickStartPageContent">
      <h1>FAQ</h1>

      <h2>How do I create a task?</h2>
      <p>Tap on the “+” button, enter a task name and tap “Save”. Other details are optional.</p>

      <h2>How do I edit a task?</h2>
      <p>Tap on the title of the task to bring up the details view, adjust as desired and tap “Save”.</p>

      <h2>How do I log progress?</h2>
      <p>Simply tap on the task below the title. There are three levels of progress: none, half, full. You can continue to tap to move through the various levels. The suggested use of this is to record half progress when you’ve spent a small amount of time on the task that day and full progress when you’ve made significant headway.</p>

      <h2>How do I create a task reminder?</h2>
      <p>Open the details view and toggle on the “Reminder” option, select the time and day(s) of the week that you wish to be reminded and tap “Save”.</p>

      <h2>How do I archive a task?</h2>
      <p>If the task is no longer relevant, open the details page, tap “Archive” and then confirm archival.</p>

      <h2>How do I unarchive a task?</h2>
      <p>From settings, open your account view and tap “View Archive” to see all your archived tasks, selected the task you wish to make active again, tap “Unarchive” and confirm.</p>

      <h2>How do I change the accent color?</h2>
      <p>Tap your preferred color from the accent color picker in settings. You can optionally make the app icon match your chosen color by tapping the “Match App Icon” button that appears upon color selection.</p>

      <h2>I still have questions, how do I get in touch?</h2>
      <p>Please contact us {" "}
        <a href="https://armchairengineering.com/support" target="_self">
          here
        </a>
        .
      </p>

    </div>);
  }
}

export default QuickStartPage;
